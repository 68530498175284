@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kollektif";
  src: url("/public/fonts/kollektif/Kollektif.ttf") format("truetype");
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 235 43.5% 27.1%;
    --primary-foreground: 210 40% 98%;

    --secondary: 359 79% 55%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 206 19% 93%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --dark-blue: #272c63;
    --light-blue: #085585;
    --dark-red: #e53030;
    --light-red: #db4646;
    --cool-gray: #e9ecee;
    --pure-white: #ffffff;
    --beige: #e6ce9f;

    --headline-font: "Kollektif", sans-serif;
  }

  .title-font {
    font-family: var(--headline-font);
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image-gallery {
  width: 40%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}
